<template lang="pug">
.benefits
  h2 Benefícios exclusivos

  .benefit-list(v-if="benefits.length > 0")
    Benefit(v-for="benefit in benefits", :key="benefit.id" :benefit="benefit")

  .no-benefits(v-else-if="showBenefits")
    | Hmm... você ainda não possui nenhum benefício disponível.

  .no-benefits(v-else)
    | Ative a sua assinatura Box Magenta para ter acesso a benefícios exclusivos!

</template>
<script setup lang="ts">
import type { PropType } from 'vue'
import type { Benefit as BenefitModel } from '~/services/benefit/benefitService'
import type { PrimarySubscription } from '~/services/subscription/subscriptionService'

import Benefit from './Benefit.vue'
import { useCustomerInfo } from '~/store/customer'

const customerInfo = useCustomerInfo()

const props = defineProps({
  benefits: {
    type: Object as PropType<BenefitModel[]>,
    required: true
  },
  subscription: {
    type: Object as PropType<PrimarySubscription | null>,
  }
})

const visibleBenefits = (() =>
  props.benefits.sort((a, b) => (a.priority ?? 0) < (b.priority ?? 0) ? 1 : -1)
)

const showBenefits = computed(() => {
  return customerInfo.influencer != null || props.subscription?.status == "ACTIVE" || (props.subscription?.status == "WAITING" && props.subscription?.migratedFrom);
})
</script>
<style lang="sass" scoped>
@import '~/assets/styles/mixins'

.benefits

  .benefit-list
    display: grid
    grid-gap: 16px
    grid-template-columns: repeat(auto-fill, minmax(156px, 1fr))


  @include breakpoint(tablet)
    .benefit-list
      grid-gap: 32px
      grid-template-columns: repeat(auto-fill, minmax(156px, 1fr))

  .no-benefits
    position: relative
    width: 100%
    padding: 24px
    text-align: center
    font-size: 18px
    color: #AAA

    &::before
      position: absolute
      display: block
      top: 4px
      left: 4px
      width: calc(100% - 8px)
      height: calc(100% - 8px)
      border: dashed 2px #dedede
      border-radius: 8px
      content: ''
      box-sizing: border-box
</style>
