<template lang="pug">
.benefit-container
  .benefit(:onClick="toggleOpened")
    .benefit-image
      img(:src="benefit.imageUrl")

    h3 {{ benefit.title }}

    .benefit-expiration(v-if="expiration")
      | Válido até {{ expiration }}

  ClientOnly
    Teleport(to="#modals")
      .benefit-popup(v-show="opened")
        .benefit-popup-dismiss(:onClick="toggleOpened")

        .benefit-popup-content
          .benefit-image
            img(:src="benefit.imageUrl")

          h3 {{ benefit.title }}

          .benefit-description(v-if="benefit.description", v-html="benefit.description")

          Button.action(
            v-if="benefit.link",
            :to="benefit.link",
            :internal="false",
            label="Quero aproveitar!",
            target="_blank"
            rel="noopener noreferrer")

          .benefit-expiration(v-if="expiration")
            | Válido até {{ expiration }}

          .benefit-popup-dismiss-button(:onClick="toggleOpened") X
</template>
<script setup lang="ts">
import  type { PropType } from 'vue'
import type { Benefit } from '~/services/benefit/benefitService'

import { useAppScroll } from '~/store/appScroll'
import { useGtm } from '@gtm-support/vue-gtm'

const props = defineProps({
  benefit: {
    type: Object as PropType<Benefit>,
    required: true
  }
})

const appScroll = useAppScroll()

const dayjs = useDayjs()
const gtm = useGtm()
const opened = ref(false)

const toggleOpened = () => {
  opened.value = !opened.value

  if (opened.value) {
    appScroll.disableScroll()
  } else {
    appScroll.enableScroll()
  }

  if (opened.value) {
    const dataLayer = gtm?.dataLayer()

    if (dataLayer) {
      dataLayer.push({
        event: "view-benefit",
        "benefitId": props.benefit.id
      })
    }
  }
}

const expiration = computed(() => {
  if (!props.benefit.expiresAt) {
    return null;
  }

  return dayjs(props.benefit.expiresAt).format("DD/MM/YYYY")
})

onUnmounted(() => {
  if (opened.value) {
    appScroll.enableScroll()
  }
})
</script>
<style lang="sass" scoped>
@import '~/assets/styles/variables'

.benefit-container
  position: relative
  min-width: 156px

  .benefit
    display: flex
    flex-direction: column
    justify-content: flex-start
    width: 100%
    height: 100%
    padding: 16px
    background-color: $beige
    color: #000
    border-radius: 8px
    text-align: center
    cursor: pointer

    h3
      margin: 0 0 8px
      color: #000
      font-size: 16px

    .benefit-image
      background-color: #FFF
      border-radius: 8px
      margin-bottom: 16px
      padding: 16px

      img
        width: 96px
        height: 96px
        object-fit: contain

    .benefit-expiration
      font-size: 12px

</style>
<style lang="sass">
@import '~/assets/styles/variables'

#modals
  .benefit-popup
    position: fixed
    display: flex
    top: 0
    left: 0
    width: 100vw
    height: 100vh
    z-index: 9998
    align-items: center
    justify-content: center
    padding: 16px

    .benefit-popup-dismiss
      position: absolute
      top: -50vh
      left: -50vw
      width: 200vw
      height: 200vh
      background-color: rgba(0, 0, 0, 0.25)
      animation: fade-in ease-out .16s forwards

    .benefit-popup-content
      position: relative
      display: flex
      flex-direction: column
      justify-content: flex-start
      align-items: left
      padding: 32px
      max-width: 480px
      min-height: 480px
      max-height: 640px
      background-color: $beige
      color: #000
      border-radius: 8px
      text-align: center
      z-index: 9999
      box-shadow: 0 4px 24px 0 rgba(0, 0, 0, 0.2)
      opacity: 0
      transform: translateY(32px)
      animation: fade-in ease-out .16s .16s forwards, slide-up ease-out .16s .16s forwards

      @keyframes slide-up
        from
          transform: translateY(32px)

        to
          transform: translateY(0)

      h3
        margin: 16px 0
        font-size: 24px

      .benefit-image
        background-color: #FFF
        border-radius: 8px
        margin-bottom: 16px
        padding: 16px

        img
          min-width: 120px
          max-width: 180px
          min-height: 120px
          max-height: 180px
          object-fit: contain

      .benefit-description
        margin-bottom: 16px
        text-align: justify
        max-height: 236px
        overflow-y: auto

      .benefit-expiration
        font-size: 12px

      a.action
        display: block
        margin: 0 auto
        width: 240px
        margin-bottom: 16px
        box-shadow: 0 4px 16px 0 rgba(238, 61, 138, 0.4)

      .benefit-popup-dismiss-button
        position: absolute
        top: -16px
        right: -16px
        width: 32px
        height: 32px
        border-radius: 16px
        background-color: $magenta
        box-shadow: 0 4px 16px 0 rgba(238, 61, 138, 0.4)
        color: #FFF
        line-height: 32px
        font-weight: bold
        text-align: center
        cursor: pointer

        &:hover
          background-color: scale-color($magenta, $lightness: +15%)
</style>
